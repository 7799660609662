.admin-radio {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: rgb(240, 240, 240);
    color: rgba(0, 0, 0, 0.54);
}

.admin-radio-label {
    padding-right: 15px;
    
}

.admin-radio-label > legend {
    font-size: 1em;
}


.admin-radio-label-helper-text-error {
    color: red;
    font-size: 1em;
}


