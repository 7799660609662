@media only screen and (max-width: 3000px) {
    .visit-animal-selection-filter-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5em;
    }
    .visit-animal-selection-filter-modal-paper {
        display: grid;
        grid-template-rows: 0.2fr 1fr;
        justify-content: center;
        height: 60%;
        width: 75%;
        padding-top: 2em;
    }
}
@media only screen and (max-width: 770px) {
    .visit-animal-selection-filter-modal {
        height: 52%;
        width: 100%;
    }
}
@media only screen and (max-width: 690px) {
    .visit-animal-selection-filter-modal {
        height: 71%;
        width: 100%;
    }
}