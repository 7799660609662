.visit-aqua-culture-radio {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    max-height: 200px;
    overflow: auto;
}
.visit-aqua-establishment-label {
    color: black;
}
.visit-aqua-establishment-label-error {
    color: red;
}
