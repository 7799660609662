.add-diagnosis-button button {
    background-color: rgb(107, 170, 107);
    height: 40px;
    width: 160px;
    font-size: 14px;
    border-radius: 0%;
    color: black;
}
.add-diagnosis-button button:hover {
    background-color: rgb(55, 87, 55);
    color: white;
}
