@media only screen and (max-width: 3000px) {
    .sidebar-container {
        width: 10em;
        height: 100%;
        border-right: solid rgb(255, 196, 87) 1.5px;
    }
}
@media only screen and (max-width: 770px) {
    .sidebar-container {
        width: 100%;
        height: 4.3em;
        border-bottom: solid rgb(255, 196, 87) 2px;
        border-right: none;
    }
}
@media only screen and (max-width: 690px) {
    .sidebar-container {
        width: 100%;
        height: 100%;
    }
}
