@media only screen and (max-width: 3000px) {
    .visit-furanimals-container {
        display: grid;
        grid-template-rows: 14fr 1fr;
        background-color: rgb(41, 39, 46);
        height: 100%;
        width: 100%;
    }
    .furanimals-selection-container {
        display: flex;
        width: 80%;
        background-color: rgb(248, 248, 248);
        justify-self: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .furanimals-diagnoses-container {
        width: 100%;
    }
    .furanimals-confirmed-container {
        width: 100%;
        height: 100%;
    }
}
@media only screen and (max-width: 690px) {
    .furanimals-selection-container {
        display: flex;
        width: 95%;
        background-color: rgb(248, 248, 248);
        justify-self: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
