div .visit-animal-selection-list {
    width: 100%;
}
div .visit-animal-selection-list-title {
    background-color: rgb(240, 246, 255);
    box-shadow: 5px 2.5px 2.5px rgb(211, 216, 255);
}
.visit-animal-selection-list-table-container {
    max-height: 400px;
    overflow: auto;
}
.visit-animal-selection-list-title-text {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}
.visit-animal-selection-list-title-text-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
}