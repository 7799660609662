.medicine-given-form-container {
    display: grid;
    grid-template-rows: 0.6fr 0.6fr 1fr 0.8fr;
    margin: 2em;
    justify-content: center;
}
.medicine-given-form-add-button {
    margin-top: 1em;
    justify-self: center;
}
