.update-visit-diagnoses-medicine-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.update-visit-diagnoses-medicine-paper {
    display: grid;
    grid-template-rows: 0.1fr 1fr 0.5fr;
    width: 80%;
    gap: 1em;
    justify-items: center;
}
.update-visit-diagnoses-medicine-paper-medicine-list {
    overflow: auto;
    width: 90%;
}
