
.admin-drop-down-list {
    background-color: rgb(240, 240, 240);
    flex: 1;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

.admin-drop-down-list p {
    font-size: 0.75em;
    padding-left: 20px;  
}

.admin-drop-down-list-select {
    padding-left: 20px;  
}

.admin-drop-down-list:hover {
    background-color: rgb(210, 210, 210);
}
