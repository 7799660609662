div .diagnoses-confirm-button {
    background-color: rgb(129, 255, 129);
}
.diagnoses-confirm-button b {
    color: rgb(44, 44, 44);
}
.diagnoses-confirm-button .diagnoses-confirm-icon {
    color: rgb(44, 44, 44);
}
div .diagnoses-confirm-button:hover {
    background-color: rgb(53, 105, 53);
}
div .diagnoses-confirm-button:disabled {
    background-color: gray;
}
.diagnoses-confirm-button:hover b {
    color: white;
}
.diagnoses-confirm-button:hover .diagnoses-confirm-icon {
    color: white;
}
