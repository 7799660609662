.update-visit-goat-modal {
    display: flex;
    justify-content: center;
    margin-top: 5em;
}
.update-visit-goat-paper {
    display: grid;
    grid-template-rows: 0.2fr 1fr 0.2fr;
    height: 60%;
    width: 75%;
}
.update-visit-goat-modal-header {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    margin-top: 0.5em;
}
.diagnoses-medicine-view-update-card-button-area {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 1em;
}

div .diagnoses-medicine-view-update-card-confirm-button {
    background-color: rgb(187, 255, 184);
    width: 100px;
}
.diagnoses-medicine-view-update-card-confirm-button b {
    color: rgb(44, 44, 44);
}
div .diagnoses-medicine-view-update-card-confirm-button:hover {
    background-color: rgb(64, 110, 53);
}
.diagnoses-medicine-view-update-card-confirm-button:hover b {
    color: white;
}
div .diagnoses-medicine-view-update-card-cancel-button {
    background-color: rgb(255, 180, 180);
    width: 100px;
}
.diagnoses-medicine-view-update-card-cancel-button b {
    color: rgb(44, 44, 44);
}
div .diagnoses-medicine-view-update-card-cancel-button:hover {
    background-color: rgb(121, 87, 87);
}
.diagnoses-medicine-view-update-card-cancel-button:hover b {
    color: white;
}
@media only screen and (max-width: 770px) {
    .diagnoses-medicine-view-update-card-button-area {
        width: 95%;
    }
}
@media only screen and (max-width: 690px) {
    .update-visit-goat-paper {
        width: 100%;
    }
    .diagnoses-medicine-view-update-card-button-area {
        width: 90%;
    }
}
