    div .back-button {
        background-color: rgb(255, 137, 129);
    }
    .back-button b {
        color: rgb(44, 44, 44);
    }
    .back-button .back-icon {
        color: rgb(44, 44, 44);
    }
    div .back-button:hover {
        background-color: rgb(245, 8, 8);
    }
    div .back-button:disabled {
        background-color: gray;
    }
    .back-button:hover b {
        color: white;
    }
    .back-button:hover .back-icon {
        color: white;
    }
    
    .admin-add-medicine-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .admin-add-medicine {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(41, 39, 46);
        color: white;
        width: 100%;
        height: 100%;
        padding-bottom: 0.5em;
    }

    .add-medicine-container {
        padding: 10px;
        margin-bottom: 1em;
        width: 80%;
        background-color: rgb(248, 248, 248);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "item-6 item-6 item-6" /* name name */
        "item-3 item-3 item-1" /* medicineType formulationTypeId */
        "item-8 item-7 item-7" /* ACTvet ProductId */
        "item-13 item-2 item-2" /* strength strengthTypeId */
        "item-9 item-5 item-5"     /* unit unitTypeId*/
        "item-4 item-4 item-4"    /* packageUnitType */
        "item-10 item-11 item-11"   /* competitionBanDays milkBanDays  */ 
        "item-12 item-12 item-12"      /* slaughterBanDays  slaughterBanDays  */
        "item-14 item-15 item-15"   /* isAntibiotic isMycobacteriumVaccine */
        "item-16 item-17 item-17"   /* isVetOnlyAdminister isBannedForFarmedAnimal */ 
        "item-18 item-18 item-18";  /* isNotForHorses isNotForHorses */
    }

    .add-medicine-item {
        display: flex;
        justify-content: center;
        padding: 5px;
    }
    
    .add-medicine-item:nth-child(1) { grid-area: item-1; }
    .add-medicine-item:nth-child(2) { grid-area: item-2; }
    .add-medicine-item:nth-child(3) { grid-area: item-3; }
    .add-medicine-item:nth-child(4) { grid-area: item-4; }
    .add-medicine-item:nth-child(5) { grid-area: item-5; }
    .add-medicine-item:nth-child(6) { grid-area: item-6; }
    .add-medicine-item:nth-child(7) { grid-area: item-7; }
    .add-medicine-item:nth-child(8) { grid-area: item-8; }
    .add-medicine-item:nth-child(9) { grid-area: item-9; }
    .add-medicine-item:nth-child(10) { grid-area: item-10; }
    .add-medicine-item:nth-child(11) { grid-area: item-11; }
    .add-medicine-item:nth-child(12) { grid-area: item-12; }
    .add-medicine-item:nth-child(13) { grid-area: item-13; }
    .add-medicine-item:nth-child(14) { grid-area: item-14; }
    .add-medicine-item:nth-child(15) { grid-area: item-15; }
    .add-medicine-item:nth-child(16) { grid-area: item-16; }
    .add-medicine-item:nth-child(17) { grid-area: item-17; }
    .add-medicine-item:nth-child(18) { grid-area: item-18; }

@media only screen and (max-width: 3000px) {

    .add-medicine-container {
        padding: 10px;
        width: 80%;
        background-color: rgb(248, 248, 248);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "item-6 item-6" /* name name */
        "item-3 item-1" /* medicineType formulationTypeId */
        "item-8 item-7" /* ACTvet ProductId */
        "item-13 item-2" /* strength strengthTypeId */
        "item-9 item-5"     /* unit unitTypeId*/
        "item-4 item-4"    /* packageUnitType */
        "item-10 item-11"   /* competitionBanDays milkBanDays  */ 
        "item-12 item-12"      /* slaughterBanDays  slaughterBanDays  */
        "item-14 item-15"   /* isAntibiotic isMycobacteriumVaccine */
        "item-16 item-17"   /* isVetOnlyAdminister isBannedForFarmedAnimal */ 
        "item-18 item-18";  /* isNotForHorses isNotForHorses */
    }
}


@media only screen and (max-width: 800px) { 

    .add-medicine-container {
        padding: 10px;
        width: 80%;
        background-color: rgb(248, 248, 248);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "item-6"    /* name */
        "item-3"    /* medicineTypeId */
        "item-7"    /* productId */ 
        "item-8"    /* ACT */
        "item-1"    /* formulationTypeId  */
        "item-13"   /* strength   */
        "item-2"    /* strengthTypeId*/ 
        "item-4"    /* packageUnitTypeId */
        "item-5"    /* unitTypeId*/
        "item-9"    /* unit */
        "item-10"   /* competitionBanDays */
        "item-11"   /* milkBanDays */
        "item-12"   /* slaughterBanDays */
        "item-14"   /*  isAntibiotic */
        "item-15"   /*  isMycobacteriumVaccine */
        "item-16"   /*  isVetOnlyAdminister */ 
        "item-17"   /*  isBannedForFarmedAnimal */
        "item-18";  /* isNotForHorses  */
    }
}

