.diagnoses-table-empty {
    display: flex;
    justify-content: center;
    margin-top: 5em;
}
.diagnoses-medicine-card-table-container {
    max-height: 500px;
    overflow: auto;
    margin: 0.5em;
}
@media only screen and (max-width: 3000px) {
    .diagnoses-medicine-card-table-container {
        max-height: 650px;
        overflow: auto;
    }
}
@media only screen and (max-width: 770px) {
    .diagnoses-medicine-card-table-container {
        max-height: 650px;
        overflow: auto;
    }
}
@media only screen and (max-width: 690px) {
    .diagnoses-medicine-card-table-container {
        max-height: 540px;
        overflow: auto;
    }
}
