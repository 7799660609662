@media only screen and (max-width: 3000px) {
    .update-visit-disease-modal {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .update-visit-disease-paper {
        display: grid;
        height: 60%;
        width: 80%;
        gap: 1em;
        justify-items: center;
    }
}
@media only screen and (max-width: 770px) {
    .update-visit-disease-paper {
        height: 52%;
    }
}
@media only screen and (max-width: 690px) {
    .update-visit-disease-paper {
        height: 71%;
    }
}
