.visit-info-view-container {
    display: grid;
    grid-template-rows: 0.3fr 1fr;
    width: 100%;
}
.visit-info-view-upper-area {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    justify-items: center;
}
.visit-info-error {
    display: flex;
    justify-self: center;
    color: red;
    margin-top: 2em;
}
.visit-info-view-lower-area {
    display: flex;
    justify-content: center;
}
