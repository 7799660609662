.animal-selection-header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
}
.animal-selection-header-item {
    margin-top: 2em;
    font-size: 10px;
    justify-self: center;
}
.animal-selection-header-establishment {
    margin-top: 1em;
}
