.slaughterban-container {
    display: grid;
    grid-template-rows: 1fr 0.2fr;
    min-height: 8em;
}
.slaughterban-calendar {
    font-size: 12px;
}
.slaughterban-infinte {
    display: flex;
    align-self: center;
}
