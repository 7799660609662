.medicine-selection-list-item-container {
    border-bottom: 1px solid black;
}
.medicine-selection-list-item-container:hover {
    background-color: rgb(215, 228, 255);
    cursor: grab;
}
.medicine-selection-list-item-name {
    padding-top: 0.5em;
}
.medicine-selection-list-item-info {
    margin-bottom: 0.5em;
}
