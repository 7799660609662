div .visit-animal-selection-list-item {
    display: grid;
    grid-template-rows: 1fr 1fr;
    box-shadow: 5px 2.5px 2.5px rgb(211, 216, 255);
}
.visit-animal-selection-list-item-upper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
div .visit-animal-selection-list-item-checkbox {
    display: flex;
    justify-self: flex-end;
}
