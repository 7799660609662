
.admin-input-label  {
    
    flex: 1;
    background-color: rgb(240, 240, 240);
}

.admin-input-label label  {
    padding-left: 20px;
    font-size: 1em;
}

.admin-input-label div input  {
    font-size: 1.4em;
    padding-left: 20px;
}

.admin-input-text-field {
    background-color: rgb(220, 220, 220);
}

.admin-input-label-helper-text {
    padding-left: 20px;
}
.admin-input-label-helper-text-error {
    color: red;
}
