.admin-medicine-selection-list-item-container {
    display: grid;
    grid-template-columns:  1fr 0.15fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid black;
}

.admin-medicine-selection-list-item-container:hover {
    background-color: rgb(215, 228, 255);
    
}

.admin-medicine-selection-list-item-button {
    display: grid;
    justify-content: flex-end;
    cursor: pointer;
    padding-right: 2.5em;
}

.admin-medicine-selection-list-item-icon-delete {
    color: rgb(235, 50, 50); 
}

.admin-medicine-selection-list-item-icon-delete:hover {
    background-color: rgb(235, 50, 50); 
    color: rgb(215, 228, 255); 
}

.admin-medicine-selection-list-item-icon-update {
    color: rgb(163, 162, 71);
}

.admin-medicine-selection-list-item-icon-update:hover {
    background-color: rgb(163, 162, 71);
    color: rgb(215, 228, 255); 
}


.admin-medicine-selection-list-item-icon-undo {
    color: rgb(17, 206, 17);
}

.admin-medicine-selection-list-item-icon-undo:hover {
    background-color: rgb(17, 206, 17);
    color: rgb(215, 228, 255); 
}

.admin-medicine-selection-list-item-modal-close-icon {
    cursor: pointer;
}

.admin-medicine-selection-list-item-modal {
    padding: 5px;
}

.admin-medicine-selection-list-item-modal-close {
    display: grid;
    justify-content: end;
}

.admin-medicine-selection-list-item-modal-confirm {
    display: grid;
    grid-template-rows: 1fr 0.2fr;
    justify-content: center;
    padding-top: 0.5em;
}

.admin-medicine-selection-list-item-modal-confirm-button {
    display: grid;
    justify-content: center;
    margin: 10px;
    padding: 10px;
}

