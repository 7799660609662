.diagnoses-medicine-card-container {
    background-color: rgb(255, 255, 255);
    border-bottom: 3px black solid;
}
.diagnoses-medicine-button-container {
    display: flex;
    justify-content: flex-end;
    height: 0.5em;
}
.diagnoses-medicine-card-title {
    display: flex;
    justify-content: center;
    color: rgb(24, 29, 27);
}
.diagnoses-medicine-card-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
.diagnoses-medicine-card-bottom-right {
    justify-self: flex-end;
}
.diagnoses-medicine-card-update-container .diagnoses-medicine-card-update-icon {
    display: initial;
    color: rgb(255, 145, 0);
}
.diagnoses-medicine-card-update-container:hover
    .diagnoses-medicine-card-update-icon {
    display: none;
}
.diagnoses-medicine-card-update-container .diagnoses-medicine-card-update-text {
    display: none;
}
.diagnoses-medicine-card-update-container:hover
    .diagnoses-medicine-card-update-text {
    display: initial;
    color: rgb(255, 145, 0);
    font-size: 15px;
}

.diagnoses-medicine-card-delete-container .diagnoses-medicine-card-delete-icon {
    display: initial;
    color: rgb(255, 0, 0);
}
.diagnoses-medicine-card-delete-container:hover
    .diagnoses-medicine-card-delete-icon {
    display: none;
}
.diagnoses-medicine-card-delete-container .diagnoses-medicine-card-delete-text {
    display: none;
}
.diagnoses-medicine-card-delete-container:hover
    .diagnoses-medicine-card-delete-text {
    display: initial;
    color: rgb(255, 0, 0);
    font-size: 15px;
}
