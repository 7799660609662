@media only screen and (max-width: 3000px) {
    .visit-pet-container {
        display: grid;
        grid-template-rows: 1fr;
        background-color: rgb(41, 39, 46);
        justify-items: center;
        height: 100%;
        width: 100%;
    }
    .pet-selection-container {
        display: grid;
        grid-template-rows: 0.1fr 1fr 1fr;
        justify-self: center;
        width: 80%;
        background-color: rgb(248, 248, 248);
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .pet-selection-confirmation-button-container {
        display: grid;
        justify-items: center;
        height: 100%;
        background-color: white;
    }
    .pet-selection-cancelation-button {
        color: red;
        cursor: pointer;
        font-size: 20px;
        margin-left: 0.5em;
    }
    .pet-selection-information-area {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 1fr;
        justify-content: center;
    }
}
@media only screen and (max-width: 690px) {
    .pet-selection-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-self: center;
        width: 95%;
        background-color: rgb(248, 248, 248);
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
