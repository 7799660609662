.admin-medicine-view-container {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
}

.admin-medicine-view-paper {
    display: grid;
    justify-items: center;
    grid-template-rows: 0.1fr 1fr;
    height: 95%;
    width: 95%;
    
}
.admin-medicine-view-header {
    display: flex;
    justify-content: center;
}

.admin-medicine-view-selection {
    width: 100%;
}

/*
.admin-medicine-view-loading {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    margin-top: 2em;
}
*/