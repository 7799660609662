.user-visit-update-view-paper-container {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.user-visit-update-view-paper {
    display: grid;
    grid-template-rows: 0.1fr 0.1fr 1fr;
    justify-items: center;
    height: 90%;
    width: 90%;
    margin: 1em;
}
.user-visit-update-visit-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.user-visit-update-update-icon {
    margin-left: 0.3em;
    color: rgb(255, 145, 0);
    cursor: pointer;
}
.user-visit-update-update-icon:hover {
    color: rgb(167, 67, 0);
    cursor: pointer;
}
.user-visit-update-view-title {
    margin: 1em;
}
.user-visit-update-horse-visit {
    width: 100%;
}
.user-visit-update-text {
    cursor: pointer;
}
.user-visit-update-horse-visit-button-area {
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin: 1em;
}
div .user-visit-update-horse-confirm-button {
    background-color: rgb(187, 255, 184);
    width: 100px;
}
.user-visit-update-horse-confirm-button b {
    color: rgb(44, 44, 44);
}
div .user-visit-update-horse-confirm-button:hover {
    background-color: rgb(64, 110, 53);
}
.user-visit-update-horse-confirm-button:hover b {
    color: white;
}
div .user-visit-update-horse-back-button {
    background-color: rgb(255, 180, 180);
    width: 100px;
}
.user-visit-update-horse-back-button b {
    color: rgb(44, 44, 44);
}
div .user-visit-update-horse-back-button:hover {
    background-color: rgb(121, 87, 87);
}
div .user-visit-update-horse-back-button:disabled {
    background-color: gray;
}
.user-visit-update-horse-back-button:hover b {
    color: white;
}
@media only screen and (max-width: 690px) {
    .user-visit-update-view-paper-container {
        height: 100%;
        width: 100%;
    }
    .user-visit-update-view-paper {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 1fr 0.1fr;
        justify-items: center;
        height: 90%;
        width: 90%;
        margin: 0em;
    }
    .user-visit-update-visit-info {
        display: grid;
        padding: 1em;
    }
    .user-visit-update-text {
        display: grid;
        grid-template-rows: 1fr 1fr;
    }
}
