.visit-horse-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(184, 184, 184);
}
.visit-horse-diagnoses-button-area {
    background-color: rgb(44, 44, 44);
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
}
.visit-horse-diagnose-container {
    width: 100%;
    height: 100%;
}
.visit-horse-diagnoses-body {
    display: grid;
    grid-template-rows: 0.1fr 1fr;
    width: 100%;
    height: 100%;
}
.visit-horse-add-button-area {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    margin-bottom: 1em;
}
.visit-horse-diagnoses-header {
    margin: 0.2em;
}
.visit-horse-modal-container {
    display: grid;
    grid-template-rows: 1fr 10fr 0.5fr;
    align-content: center;
    height: 90%;
    width: 95%;
}
