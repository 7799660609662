@media only screen and (max-width: 3000px) {
    .poultry-selection-container-paper {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    .poultry-selection-details-upper {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        justify-content: center;
        width: 100%;
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .poultry-selection-details-lower {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4em;
        width: 100%;
        justify-items: center;
    }
}
@media only screen and (max-width: 690px) {
    .poultry-selection-details-lower {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
