@media only screen and (max-width: 3000px) {
    a.visit-view-item:hover {
        background-color: rgb(41, 39, 46);
    }
    .visit-view-list-item {
        display: grid;
        grid-template-rows: 0.2fr 1fr;
        justify-content: center;
        padding-bottom: 1em;
    }
    .visit-view-list-item:hover {
        display: grid;
        grid-template-rows: 0.2fr 1fr;
        justify-content: center;
        background-color: rgb(53, 50, 59);
    }
    .visit-view-list-item-image img {
        display: flex;
        justify-self: center;
        height: 7em;
    }
    .visit-view-list-item-animal {
        display: flex;
        justify-content: center;
    }
    .visit-view-list-item-animal p {
        font-size: 15px;
        font-weight: bold;
    }
}
@media only screen and (max-width: 770px) {
    .visit-view-list-item-image img {
        height: 6em;
    }
    .visit-view-list-item-animal p {
        font-size: 14px;
    }
}
@media only screen and (max-width: 690px) {
    .visit-view-list-item-image img {
        height: 5em;
    }
    .visit-view-list-item-animal p {
        font-size: 13px;
    }
}

.visit-view-item {
    color: white;
    text-decoration: none;
}
.visit-view-item:hover {
    background-color: rgb(76, 74, 83);
    text-decoration: none;
}
