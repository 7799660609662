.user-visit-update-view-diagnoses-list {
    width: 100%;
    max-height: 570px;
    overflow: auto;
}
@media only screen and (max-width: 690px) {
    .user-visit-update-view-diagnoses-list {
        width: 100%;
        max-height: 440px;
        overflow: auto;
    }
}
