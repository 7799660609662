.selection-table-row {
    border-bottom: 1px solid rgb(194, 194, 194);
}
.selection-table-row:hover {
    background-color: rgb(215, 228, 255);
    cursor: grab;
}
div .selection-table-item {
    display: grid;
    grid-template-rows: 1fr 1fr;
    border-bottom: none;
}
