.update-visit-arrangement-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.update-visit-arrangement-paper {
    display: grid;
    height: 30%;
    width: 60%;
    padding: 1em;
    justify-items: center;
}
