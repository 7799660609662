@media only screen and (max-width: 3000px) {
    .disease-selection-view-container {
        display: grid;
        grid-template-rows: 0.2fr 0.1fr 0.1fr 1fr;
        height: 100%;
        width: 100%;
    }
    .diagnoses-selection-selected-container {
        height: 100%;
    }
    .disease-selection-view-switch-button-container {
        display: flex;
        justify-content: flex-end;
    }
    .disease-selection-selected-header {
        display: flex;
        justify-content: center;
        margin: 1em;
    }
    .disease-selection-view-searchbar {
        display: flex;
        justify-self: center;
    }
    .disease-selection-header-error {
        color: red;
    }
}
@media only screen and (max-width: 770px) {
    .disease-selection-view-container {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 0.1fr 1fr;
        height: 100%;
        width: 100%;
    }
}
@media only screen and (max-width: 690px) {
    .disease-selection-view-container {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 0.1fr 1fr;
        height: 100%;
        width: 100%;
    }
}
