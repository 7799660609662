.admin-create-user-view {
    display: grid;
    grid-template-rows: 0.1fr 1fr;
    justify-content: center;
    background-color: rgb(41, 39, 46);
    height: 100%;
    color: white;
}
.admin-create-user-view-header {
    margin-top: 5em;
}
