.visit-container {
    display: grid;
    grid-template-rows: 20% 70% 10%;
    justify-content: center;
    background-color: rgb(41, 39, 46);
    color: white;
    height: 100%;
    width: 100%;
}
.visit-view-title {
    margin-top: 2em;
}

@media only screen and (max-width: 690px) {
    .visit-view-title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
}
