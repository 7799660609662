@media only screen and (max-width: 3000px) {
    .sidebar-item-container {
        display: grid;
        grid-template-rows: 5fr 1fr;
        height: 100%;
    }
    .sidebar-items-list {
        background-color: rgb(41, 39, 46);
        color: rgb(192, 190, 190);
    }

    .sidebar-user-list {
        display: grid;
        grid-template-rows: 1fr 1fr;
        background-color: rgb(41, 39, 46);
        color: rgb(192, 190, 190);
        text-align: center;
    }
    .sidebar-item .MuiListItem-root {
        font-size: 16px;
    }
    .sidebar-user-logout {
        cursor: pointer;
    }
    .sidebar-flag {
        height: 20px;
        width: 30px;
        margin-left: 1em;
    }
    .logout-text {
        display: block;
    }
    .sidebar-user-bottom-list {
        display: grid;
        grid-template-rows: 0.5fr 1fr;
    }
    .sidebar-user-name {
        display: show;
    }
}
@media only screen and (max-width: 770px) {
    .sidebar-item-container {
        display: grid;
        grid-template-columns: 6fr 1fr;
        height: 4em;
        width: 100%;
    }
    .sidebar-items-list {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
    }
    .sidebar-user-list {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
    }
    .sidebar-user-text {
        display: none;
    }
    .sidebar-item .MuiListItem-root {
        font-size: 12px;
    }
    .logout-text {
        display: none;
    }
    .sidebar-user-logout {
        display: flex;
        justify-content: center;
    }
    div .logout-icon {
        font-size: 18px;
    }
    .sidebar-user-bottom-list {
        display: grid;
        grid-template-rows: 1fr;
    }
    .sidebar-user-name {
        display: none;
    }
}
@media only screen and (max-width: 690px) {
    .sidebar-item-container {
        display: grid;
        height: 100%;
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 1fr;
    }
    .sidebar-items-list {
        display: flex;
        flex-direction: column;
    }
    .sidebar-user-text {
        display: show;
    }
    .sidebar-item .MuiListItem-root {
        font-size: 12px;
    }
    .sidebar-user-logout {
        display: flex;
        justify-content: center;
    }
}

.sidebar-item {
    color: rgb(192, 190, 190);
    text-decoration: none;
}
.sidebar-item:hover {
    background-color: rgb(76, 74, 83);
    text-decoration: none;
}

li.Mui-selected {
    color: pink;
}
