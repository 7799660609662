
.admin-management-container {
    background-color: rgb(41, 39, 46);
    height: 100%;
    width: 100%;
}

.admin-management-view-title {
    margin-top: 2em;
    text-align: center;
    color: white;
}

@media only screen and (max-width: 690px) {
    .admin-management-view-title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
}
