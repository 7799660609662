.login-landing-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: url("../../../images/loginimage.JPG");
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}
.login-page-login-container {
    display: grid;
    grid-template-rows: 2fr 5fr;
    justify-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 90%;
    height: 90%;
}
.login-page-login-container .login-page-login-title {
    padding-top: 10%;
    color: white;
}
