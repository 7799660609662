.admin-medicine-select-view-container {
    /*
    display: grid;
    grid-template-rows: 0.3fr 1fr;
    */
    width: 100%;
}
.admin-medicine-select-view-upper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.admin-medicine-select-view-show-active {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
    justify-self: end;
    align-self: flex-end;
}
.admin-medicine-select-view-middle {
    display: flex;
    justify-content: center;
}
.admin-medicine-select-view-lower {

    width: 100%;
}