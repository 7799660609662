.pet-visit-date-range-picker-view-container {
    background-color: white;
    max-height: 100px;
}
.pet-visit-date-range-picker-container {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    margin-left: 1em;
}
.pet-visit-date-range-pick-error {
    display: flex;
    justify-content: center;
    color: red;
}
div .pet-visit-calendar-picker-start {
    margin-right: 1em;
}
div .pet-visit-calendar-picker-end {
    margin-left: 1em;
}
