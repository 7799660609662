.visit-horse-selection-container {
    display: grid;
    grid-template-rows: 0.3fr 1fr;
    width: 100%;
}
.visit-horse-selection-upper-area {
    display: grid;
    grid-template-rows: 0.8fr 1fr;
    width: 100%;
    justify-items: center;
}
.visit-horse-selection-lower-area {
    display: flex;
    justify-content: center;
}
