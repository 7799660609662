@media only screen and (max-width: 3000px) {
    .area-selection-container {
        display: grid;
        grid-template-rows: 0.4fr 1fr;
        height: 100%;
    }
    .area-selection-items-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .area-selection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        background-color: rgb(236, 253, 250);
        padding-top: 2em;
        padding-left: 0.5em;
        max-height: 220px;
    }
    .area-selection-header {
        display: grid;
        justify-content: center;
    }
    .area-selection-item {
        display: grid;
        justify-content: center;
        margin: 1em;
    }
}
