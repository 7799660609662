.add-medicine-close-modal-button-area {
    display: flex;
    justify-content: center;
    color: green;
}
div .add-medicine-close-modal-button {
    margin-top: 1em;
    background-color: rgb(255, 206, 206);
}
.add-medicine-close-modal-button b {
    color: black;
}
div .add-medicine-close-modal-button:hover {
    margin-top: 1em;
    background-color: rgb(104, 66, 66);
}
.add-medicine-close-modal-button:hover b {
    color: white;
}
