.update-visit-date-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.update-visit-date-paper {
    display: grid;
    height: 30%;
    width: 60%;
    padding-top: 2em;
    justify-items: center;
}
