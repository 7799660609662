.visit-pet-culture-select-list {
    display: grid;
    justify-content: center;
}
.visit-pet-culture-select-label {
    margin-bottom: 0.5em;
}
.visit-pet-species-label {
    color: black;
}
.visit-pet-species-label-error {
    color: red;
}
