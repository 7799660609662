@media only screen and (max-width: 3000px) {
    .medicine-selection-container-view {
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 1fr;
        justify-items: center;
        height: 100%;
        width: 100%;
    }
    .medicine-selection-container-modal {
        display: grid;
        grid-template-rows: 1fr;
        justify-items: center;
        height: 100%;
        width: 100%;
    }
    .medicine-selection-button-area {
        margin-top: 1em;
    }
    .medicine-selection-selected-table {
        margin-top: 2em;
    }
    .medicine-selection-error {
        color: red;
        margin-top: 0.5em;
    }
}
