div .diagnoses-cancel-button {
    background-color: rgb(255, 180, 180);
    margin-right: 0.3em;
}
.diagnoses-cancel-button b {
    color: rgb(44, 44, 44);
}
.diagnoses-cancel-button .diagnoses-cancel-icon {
    color: rgb(44, 44, 44);
}
div .diagnoses-cancel-button:hover {
    background-color: rgb(121, 87, 87);
}
div .diagnoses-cancel-button:disabled {
    background-color: gray;
}
.diagnoses-cancel-button:hover b {
    color: white;
}
.diagnoses-cancel-button:hover .diagnoses-cancel-icon {
    color: white;
}
