.admin-create-user-form-container {
    justify-content: center;
}
.admin-create-user-item {
    margin-top: 1em;
}
div .input-form-label {
    color: white;
}
div .admin-create-user-input {
    color: white;
    border-color: white !important;
}
.admin-create-user-form-container .MuiInput-underline:before {
    border-bottom-color: white;
}
.admin-create-user-form-container .MuiInput-underline:after {
    border-bottom-color: white;
}
.create-user-button-container {
    display: flex;
    justify-content: center;
}
div .create-user-button {
    width: 200px;
    border: 3px solid white;
}
div .create-user-success-alert {
    background-color: rgb(41, 39, 46);
    color: green;
}
div .create-user-error-alert {
    background-color: rgb(41, 39, 46);
    color: red;
}
