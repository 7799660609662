.MuiButton-label {
    color: white;
}
.MuiMobileStepper-dots {
    position: fixed;
    right: 45%;
}
div.MuiMobileStepper-dot {
    background-color: white;
}
div.MuiMobileStepper-dotActive {
    background-color: rgb(255, 0, 0);
}
.stepper-button-text {
    font-size: 16px;
    font-weight: bold;
}
