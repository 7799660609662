.disease-view-selected-container {
    display: grid;
    grid-template-rows: 2fr 1fr;
    justify-items: center;
    margin-top: 1em;
}
.disease-selection-selected-items {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    justify-items: center;
}
div .disease-selection-selected-header-button {
    color: red;
    font-size: 12px;
}
div .disease-selection-selected-header-button:hover {
    background-color: white;
}
