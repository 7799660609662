@media only screen and (max-width: 3000px) {
    .visit-animal-selection-container {
        display: grid;
        height: 100%;
        width: 100%;
    }
    .visit-animal-selection-header {
        display: grid;
        grid-template-rows: 0.1fr 1fr;
        width: 100%;
        justify-content: center;
        justify-items: center;
        margin-top: 1em;
        font-weight: bolder;
    }
    .visit-animal-selection-list-container {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        column-gap: 0.5em;
    }
    .visit-animal-selection-searchbar-and-filter {
        display: grid;
        width: 100%;
        justify-content: center;
        margin-bottom: 1em;
    }
    .visit-animal-selection-searchbar {
        display: grid;
        width: 100%;
        justify-content: center;
    }
    .visit-animal-selection-filter {
        display: grid;
        grid-template-columns: 1fr 70fr;
        align-items: center;
        width: 100%;        
        margin-left: 1em;
    }
    .visit-animal-selection-header-error {
        color: red;
    }
}
