.live-stock-establishments-selection-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr 10fr;
    justify-content: center;
}
.live-stock-establishments-selection-searchbar {
    justify-self: center;
}
.live-stock-establishments-selection-list-container {
    max-height: 420px;
    overflow: auto;
    padding-right: 20px;
}
.establishment-error {
    display: flex;
    justify-content: center;
    color: red;
}
