.medicine-selection-view-container {
    display: grid;
    grid-template-rows: 0.1fr 1fr;
}
.medicine-selection-selected {
    margin-top: 1em;
}
.medicine-selection-selected-header {
    color: rgb(66, 66, 66);
}
.medicine-selection-view-searchbar {
    display: flex;
    justify-self: center;
    margin: 1em;
}
.error {
    justify-self: center;
    margin-top: 1em;
    color: red;
}
