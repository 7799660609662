.visit-furanimals-confirmed-info-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-items: center;
}
.visit-furanimals-confirmed-info-upper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 1em;
}
div .visit-furanimals-confirmed-button {
    background-color: rgb(63, 63, 63);
    margin: 0.2em;
}
div .visit-furanimals-confirmed-button:hover {
    background-color: rgb(63, 63, 63);
    margin: 0.2em;
}
.visit-furanimals-confirmed-loading {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
