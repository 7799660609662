.update-visit-button-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    padding-bottom: 1em;
}
div .update-visit-confirm-button {
    background-color: rgb(129, 255, 129);
    color: black;
}
div .update-visit-confirm-button:hover {
    background-color: rgb(53, 105, 53);
    color: white;
}
div .update-visit-cancel-button {
    background-color: rgb(255, 180, 180);
    color: black;
}
div .update-visit-cancel-button:hover {
    background-color: rgb(121, 87, 87);
    color: white;
}
.update-visit-confirm-button b {
    color: rgb(44, 44, 44);
}
.update-visit-cancel-button b {
    color: rgb(44, 44, 44);
}
.update-visit-confirm-button:hover b {
    color: white;
}
.update-visit-cancel-button:hover b {
    color: white;
}
