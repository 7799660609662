.visit-horse-selection-list {
    width: 100%;
}
.visit-horse-waiting-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    justify-items: center;
}
.visit-horse-no-text {
    display: grid;
}
