.pet-selection-container-paper {
    display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    justify-items: center;
    height: 100%;
    width: 100%;
}
.pet-selection-container-upper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    margin: 1em;
    height: 100%;
    width: 100%;
}
