div .add-medicine-button {
    background-color: rgb(187, 255, 184);
}
.add-medicine-button b {
    color: rgb(44, 44, 44);
}
.add-medicine-button .add-medicine-icon {
    color: rgb(44, 44, 44);
}
div .add-medicine-button:hover {
    background-color: rgb(64, 110, 53);
}
.add-medicine-button:hover b {
    color: white;
}
.add-medicine-button:hover .add-medicine-icon {
    color: white;
}
