.live-stock-establishments-selection-list {
    width: 100%;
}
.live-stock-establishment-waiting-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    justify-items: center;
}
.live-stock-establishment-no-text {
    display: grid;
}
