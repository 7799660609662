.admin-management-view-item {
    text-align: center;
    display: flex;
    justify-content: center;  
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
}

.admin-management-view-item:hover {
    background-color: rgb(76, 74, 83);
    text-decoration: none;
}


@media only screen and (max-width: 3000px) {
    .admin-management-view-list-item {
        display: grid;
        grid-template-rows: 0.2fr 1fr; 
    }
}
