.user-visit-view-container {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
}
.user-visit-view-loading {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    margin-top: 2em;
}
.user-visit-view-paper {
    display: grid;
    grid-template-rows: 0.1fr 0.2fr 0.15fr;
    justify-items: center;
    height: 95%;
    width: 95%;
}
.user-visit-view-container-header {
    justify-self: center;
}
.user-visit-view-list-container {
    max-height: 410px;
    overflow: auto;
}
.user-visit-view-container-header-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 100%;
}
.user-visit-view-notification-bell-container {
    display: grid;
    justify-items: flex-end;
    padding: 3em 6em 0em 0em;
    color: rgb(0, 0, 0);
}
