.medicine-selected-item-container {
    margin-top: 1em;
}
.medicine-selected-item-header {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    margin-top: 1em;
    font-size: 16px;
}
.medicine-selected-item-name {
    display: flex;
    justify-content: center;
    font-size: 18px;
}
