.update-visit-animal-selection-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}
.update-visit-animal-selection-paper {
    display: grid;
    grid-template-rows: 2fr 0.1fr;
    width: 95%;
    height: 95%;
    gap: 1em;
    justify-items: center;
}
.update-visit-animal-selection-list {
    overflow: auto;
    height: 90%;
    width: 90%;
}
