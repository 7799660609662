.user-visit-update-view-diagnoses-info {
    display: grid;
    grid-auto-flow: row;
    max-width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
}
.user-visit-update-view-diagnoses-info:nth-child(odd) {
    background-color: rgb(226, 226, 226);
}
.user-visit-update-view-diagnoses-info:nth-child(even) {
    background-color: rgb(196, 196, 196);
}
.user-visit-update-view-diagnoses-site-info {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
}
.user-visit-update-view-diagnoses-area-info {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
}
.user-visit-update-view-diagnoses-medicine-info {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
}
.user-visit-update-text {
    cursor: pointer;
}
.user-visit-update-update-icon {
    margin-left: 0.3em;
    color: rgb(255, 145, 0);
    cursor: pointer;
}
.user-visit-update-update-icon:hover {
    color: rgb(167, 67, 0);
    cursor: pointer;
}
@media only screen and (max-width: 690px) {
    .user-visit-update-view-diagnoses-info {
        display: grid;
        grid-template-rows: 1.2fr 1fr 1fr;
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .user-visit-update-text {
        display: grid;
        grid-template-rows: 0.4fr 1fr;
    }
    .user-visit-update-view-diagnoses-area-info {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        cursor: pointer;
    }
    .user-visit-update-view-diagnoses-site-info {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        margin-left: 1em;
    }
    .user-visit-update-species-phone {
        display: flex;
        justify-items: center;
        margin-top: 1em;
    }
    .user-visit-update-species-phone-text {
        padding-right: 0.2em;
        margin-left: 40%;
    }
}
