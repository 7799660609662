.login-item {
    margin-top: 1em;
}
div .input-form-label {
    color: white;
}
div .login-form-item {
    color: white;
    border-color: white !important;
}
.login-error {
    color: rgb(146, 0, 0);
}
.login-form-container .MuiInput-underline:before {
    border-bottom-color: white;
}
.login-form-container .MuiInput-underline:after {
    border-bottom-color: white;
}
.login-password-visibilty {
    color: white;
}
.login-button-container {
    display: flex;
    justify-content: center;
}
div .login-button {
    width: 200px;
    border: 3px solid white;
}
.login-form-error {
    color: red;
}
