@media only screen and (max-width: 3000px) {
    .App {
        display: grid;
        grid-template-columns: 1fr 9fr;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: rgb(41, 39, 46);
    }
    .sidebar {
        background-color: rgb(41, 39, 46);
    }
}
@media only screen and (max-width: 770px) {
    .App {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 16fr;
    }
}
@media only screen and (max-width: 690px) {
    .sidebar-toggler {
        display: flex;
        width: 100%;
        height: 2em;
        padding-top: 1em;
        background-color: rgb(41, 39, 46);
        border-bottom: solid rgb(255, 196, 87) 2px;
        color: rgb(192, 190, 190);
        justify-content: center;
    }
}
